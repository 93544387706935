<template>
  <div class="separator-wrapper">
    <div :class="{'separator': !hide, 'separator-hidden': hide, }" />
  </div>
</template>

<script lang="ts">
export default defineNuxtComponent({
  props: {
    hide: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style scoped>
.separator-wrapper {
  @apply py-8;
  @apply my-4;
}
.separator {
  border-width: 0.5px;
  @apply border-solid;
  @apply border-spl-gray-1;
  @apply w-full;
  @apply h-0;
}

.separator-hidden {
  @apply border-none;
  @apply w-full;
  @apply h-0;
}
</style>
